import React, { createContext, useContext, useEffect, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface PageTitleContextProps {
  setTitle: (title: string) => void;
}

const PageTitleContext = createContext<PageTitleContextProps | undefined>(
  undefined
);

export const usePageTitle = () => {
  const context = useContext(PageTitleContext);
  if (!context) {
    throw new Error("usePageTitle must be used within a PageTitleProvider");
  }
  return context;
};

interface PageTitleProviderProps {
  children: ReactNode;
}

const routeTitleMap: { [key: string]: string } = {
  "/": "routers.home",
  "/services": "routers.services",
  "/contactUs": "routers.contactUs",
  "/aboutUs": "routers.aboutUs",
  "/blogs": "routers.blogs",
  "/newpost": "routers.newpost",
  "/dashboard": "routers.dashboard",
  "/admin/manage/posts": "routers.posts",
  "/admin/manage/contactUs": "routers.managecontactUs",
  "/admin/manage/categories": "routers.categories",
  "/admin/manage/banners": "routers.banners",
  "/login": "routers.loginAdmin",
};

export const PageTitleProvider: React.FC<PageTitleProviderProps> = ({
  children,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const setTitle = (title: string) => {
    document.title = t(title);
  };

  useEffect(() => {
    const path = location.pathname;
    const titleKey = routeTitleMap[path] || "routers.home";
    setTitle(titleKey);
  }, [location, t]);

  return (
    <PageTitleContext.Provider value={{ setTitle }}>
      {children}
    </PageTitleContext.Provider>
  );
};
