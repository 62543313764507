import { lazy } from "react";
import type { RouteObject } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import AdminRoutes from "app/pages/adminPages/Router";
import Login from "app/auth/Login";

const AdminMainPage = lazy(() => import("app/pages/adminPages"));

const router: RouteObject[] = [
  {
    path: "/admin/*",
    element: (
      <PrivateRoute
        component={AdminMainPage}
        title={"router.pages"}
        requiresAuth={true}
      />
    ),
    children: AdminRoutes,
  },
  {
    path: "/login",
    element: (
      <PrivateRoute
        component={Login}
        title={"routers.loginAdmin"}
        requiresAuth={false}
      />
    ),
  },
];

export default router;
