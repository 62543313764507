import React, { FC, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import type { IPrivateRouteProps } from "./type";
import { usePageTitle } from "components/PageTitleContext";

const PrivateRoute: FC<IPrivateRouteProps> = ({ component: Component, title, requiresAuth }) => {
  const { setTitle } = usePageTitle();
  const token = localStorage.getItem("token"); 

  useEffect(() => {
    if (title) {
      setTitle(title);
    }
  }, [title, setTitle]);

  if (requiresAuth && !token) {
    return <Navigate to="/login" replace />;
  }

  return Component ? <Component /> : <Outlet />;
};

export default PrivateRoute;
