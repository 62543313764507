import React, { useState } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import {
  LockOutlined,
  PhoneOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useLogin } from "services/api";
import { useNavigate } from "react-router-dom";
import "./login.moudle.scss";

const validateMobile = (rule: any, value: string) => {
  const iranMobileRegex = /^09\d{9}$/;
  if (!value || iranMobileRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject("شماره موبایل معتبر نیست");
};

const Login: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const loginMutation = useLogin();
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState(false);

  const handleLogin = (values: any) => {
    loginMutation.mutate(values, {
      onSuccess: (response) => {
        if (response.status === 200) {
          const token = response.data.token;
          localStorage.setItem("token", token);
          message.success(t("login.success"));
          navigate("/admin/dashboard");
        }
      },
      onError: (error: any) => {
        if (axios.isAxiosError(error) && error.response) {
          const errorMessage =
            error.response.data?.error ||
            error.response.data ||
            t("login.error");
          message.error(`Error: ${errorMessage}`);
        } else {
          message.error(t("login.error"));
        }
      },
    });
  };

  const handleValuesChange = () => {
    form
      .validateFields()
      .then(() => {
        setIsFormValid(true);
      })
      .catch(() => {
        setIsFormValid(false);
      });
  };

  const handleFinishFailed = () => {
    setIsFormValid(false);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  const imglogin = "assets/login/login-cuate.svg";
  const imglogo = "assets/logo/logopanel.png";

  return (
    <div className="login-container">
      <Row gutter={[16, 16]} className="login-row">
        {i18n.dir() === "rtl" ? (
          <>
            <Col
              xs={24}
              sm={24}
              md={{ span: 8, offset: 0 }}
              lg={{ span: 8, offset: 0 }}
              xl={{ span: 8, offset: 0 }}
              className="form-section-login"
            >
              <div className="form-content">
                <div className="title-form">
                  <div className="logo-form">
                    <img src={imglogo} alt="Network Logo" />
                  </div>
                  <h2>{t("login.title")}</h2>
                </div>

                <Form
                  name="login_form"
                  form={form}
                  initialValues={{ remember: true }}
                  onFinish={handleLogin}
                  layout="vertical"
                  onValuesChange={handleValuesChange}
                  onFinishFailed={handleFinishFailed}
                >
                  <Form.Item
                    label={t("login.mobile")}
                    name="mobile"
                    rules={[
                      { required: true, message: t("login.warningmobile") },
                      { validator: validateMobile },
                    ]}
                    className="custom-form-item"
                    validateTrigger="onBlur"
                  >
                    <Input
                      prefix={<PhoneOutlined />}
                      className="custom-input"
                      inputMode="numeric"
                      onKeyPress={handleKeyPress}
                      pattern="[0-9]*"
                    />
                  </Form.Item>
                  <Form.Item
                    label={t("login.password")}
                    name="password"
                    rules={[
                      { required: true, message: t("login.warningpassword") },
                    ]}
                    className="custom-form-item"
                    validateTrigger="onBlur"
                  >
                    <Input.Password
                      prefix={<LockOutlined />}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      className="custom-input"
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="btn-primary"
                      block
                      loading={loginMutation.isLoading}
                      disabled={!isFormValid}
                    >
                      {t("login.login")}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
            <Col
              xs={0}
              sm={0}
              md={{ span: 15, offset: 1 }}
              lg={{ span: 15, offset: 1 }}
              xl={{ span: 15, offset: 1 }}
              className="image-section-login"
            >
              <div className="img-login">
                <img src={imglogin} alt="Login Illustration" />
              </div>
            </Col>
          </>
        ) : (
          <>
            <Col
              xs={0}
              sm={0}
              md={15}
              lg={15}
              xl={15}
              className="image-section-login"
            >
              <div className="img-login">
                <img src={imglogin} alt="Login Illustration" />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={{ span: 8, offset: 1 }}
              lg={{ span: 8, offset: 1 }}
              xl={{ span: 8, offset: 1 }}
              className="form-section-login"
            >
              <div className="form-content">
                <div className="title-form">
                  <h2>{t("login.title")}</h2>
                  <div className="logo-form">
                    <img src={imglogo} alt="Network Logo" />
                  </div>
                </div>

                <Form
                  name="login_form"
                  form={form}
                  initialValues={{ remember: true }}
                  onFinish={handleLogin}
                  layout="vertical"
                  onValuesChange={handleValuesChange}
                  onFinishFailed={handleFinishFailed}
                >
                  <Form.Item
                    label={t("login.mobile")}
                    name="mobile"
                    rules={[
                      { required: true, message: t("login.warningmobile") },
                      { validator: validateMobile },
                    ]}
                    className="custom-form-item"
                    validateTrigger="onBlur"
                  >
                    <Input
                      prefix={<PhoneOutlined />}
                      className="custom-input"
                      inputMode="numeric"
                      onKeyPress={handleKeyPress}
                      pattern="[0-9]*"
                    />
                  </Form.Item>
                  <Form.Item
                    label={t("login.password")}
                    name="password"
                    rules={[
                      { required: true, message: t("login.warningpassword") },
                    ]}
                    className="custom-form-item"
                    validateTrigger="onBlur"
                  >
                    <Input.Password
                      prefix={<LockOutlined />}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      className="custom-input"
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="btn-primary"
                      block
                      loading={loginMutation.isLoading}
                      disabled={!isFormValid}
                    >
                      {t("login.login")}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default Login;
