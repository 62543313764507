import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { baseUrl } from "config";
import {
  LoginRequest,
  LoginResponse,
  PostsResponse,
  FetchPostsParams,
  CategoriesResponse,
  Category,
  UploadImageResponse,
  CreatePostData,
  Contact,
  ContactDetail,
  CategoryDetailResponse,
  CreateCategoryData,
  UpdateCategoryData,
  EditPostData,
  Banner,
  AuthorsResponse,
} from "./types";

const api = axios.create({
  baseURL: baseUrl,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("userName");
      localStorage.removeItem("role");
      localStorage.removeItem("roleEn");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export const useLogin = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: LoginRequest) => api.post<LoginResponse>("/v1/auth/login", data),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          const { token, id, name, role, roleEn } = response.data;
          localStorage.setItem("token", token);
          localStorage.setItem("userId", id);
          localStorage.setItem("userName", name);
          localStorage.setItem("role", role);
          localStorage.setItem("roleEn", roleEn);
          queryClient.invalidateQueries("user");
        }
      },
    }
  );
};

export const fetchPosts = async ({
  page,
  limit,
  trash,
  status,
  from,
  to,
  author,
}: FetchPostsParams): Promise<PostsResponse> => {
  try {
    const response = await api.get<PostsResponse>("/v1/admin/posts/list", {
      params: {
        page,
        limit,
        trash,
        status,
        ...(from && { from }),
        ...(to && { to }),
        author,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch posts:", error);
    throw error;
  }
};

export const createPost = async (postData: CreatePostData) => {
  try {
    const response = await api.post("/v1/admin/post/create", postData);
    return response.data;
  } catch (error) {
    console.error("Failed to create post:", error);
    throw error;
  }
};

export const fetchPostDetail = async (
  postId: string
): Promise<EditPostData> => {
  try {
    const response = await api.get<EditPostData>(
      `/v1/admin/post/${postId}/getData`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch post details:", error);
    throw error;
  }
};

export const updatePost = async (
  postId: string,
  postData: CreatePostData
): Promise<{ message: string }> => {
  try {
    const response = await api.patch<{ message: string }>(
      `/v1/admin/post/${postId}/edit`,
      postData
    );
    return response.data;
  } catch (error) {
    console.error("Failed to edit post:", error);
    throw error;
  }
};

export const updatePostStatus = async ({
  postId,
  status,
}: {
  postId: string;
  status: string;
}): Promise<{ message: string }> => {
  try {
    const response = await api.patch<{ message: string }>(
      `/v1/admin/post/${postId}/modify`,
      { status }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update post status:", error);
    throw error;
  }
};

export const deletePost = async (
  postId: string
): Promise<{ message: string }> => {
  try {
    const response = await api.delete<{ message: string }>(
      `/v1/admin/post/${postId}/delete`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to delete post:", error);
    throw error;
  }
};

export const fetchCategories = async (
  page: number,
  limit: number
): Promise<CategoriesResponse> => {
  try {
    const response = await api.get<CategoriesResponse>(
      "/v1/admin/categories/list",
      {
        params: { page, limit },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch categories:", error);
    throw error;
  }
};

export const fetchAuthors = async (): Promise<AuthorsResponse> => {
  try {
    const response = await api.get<AuthorsResponse>("/v1/admin/posts/filters");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch authors:", error);
    throw error;
  }
};

export const uploadImage = async (file: File): Promise<UploadImageResponse> => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("section", "post");

  try {
    const response = await api.post<UploadImageResponse>(
      "/v1/admin/upload/post",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to upload image:", error);
    throw error;
  }
};

export const uploadEditImage = async (
  formData: FormData
): Promise<UploadImageResponse> => {
  try {
    const response = await api.post<UploadImageResponse>(
      "/v1/admin/upload/post",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to upload image:", error);
    throw error;
  }
};

export const fetchContacts = async (): Promise<Contact[]> => {
  try {
    const response = await api.get<Contact[]>("/v1/admin/contactForms/list");

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchContactDetail = async (
  id: string
): Promise<ContactDetail> => {
  try {
    const response = await api.get<ContactDetail>(
      `/v1/admin/contactForm/${id}/getData`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchListCategories = async ({
  page,
  limit,
}: {
  page: number;
  limit: number;
}): Promise<{
  categories: Category[];
  pager: { current: number; total: number; limit: number };
}> => {
  try {
    const response = await api.get<CategoriesResponse>(
      "/v1/admin/categories/list",
      {
        params: { page, limit },
      }
    );
    const { data, pager } = response.data;
    return { categories: data, pager };
  } catch (error) {
    console.error("Failed to fetch categories:", error);
    throw error;
  }
};

export const fetchCategoryDetail = async (
  categoryId: string
): Promise<CategoryDetailResponse> => {
  try {
    const response = await api.get<CategoryDetailResponse>(
      `/v1/admin/category/${categoryId}/getData`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Failed to fetch category details");
    }
  } catch (error) {
    console.error("Failed to fetch category details:", error);
    throw error;
  }
};

export const createCategory = async (category: CreateCategoryData) => {
  try {
    const response = await api.post<{ message: string }>(
      "/v1/admin/category/create",
      category
    );
    return response;
  } catch (error) {
    console.error("Failed to create category:", error);
    throw error;
  }
};

export const updateCategory = async (
  categoryId: string,
  category: UpdateCategoryData
) => {
  try {
    const response = await api.patch<{ message: string }>(
      `/v1/admin/category/${categoryId}/edit`,
      category
    );
    return response;
  } catch (error) {
    console.error("Failed to update category:", error);
    throw error;
  }
};

export const deleteCategory = async (categoryId: string) => {
  try {
    await api.delete(`/v1/admin/category/${categoryId}/delete`);
  } catch (error) {
    console.error("Failed to delete category:", error);
    throw error;
  }
};

export const fetchListBanners = async (): Promise<Banner[]> => {
  try {
    const response = await api.get<Banner[]>("/v1/admin/banners/list");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch banners:", error);
    throw error;
  }
};

export const uploadBannerImage = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("section", "banner");

  try {
    const response = await api.post("/v1/admin/upload/banner", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to upload image:", error);
    throw error;
  }
};

export const createBanner = async (banner: {
  text: string;
  link: string;
  imageId: string;
}) => {
  try {
    const response = await api.post("/v1/admin/banner/create", banner);
    return response.data;
  } catch (error) {
    console.error("Failed to create banner:", error);
    throw error;
  }
};

export const fetchBannerDetail = async (bannerId: string): Promise<Banner> => {
  try {
    const response = await api.get<Banner>(
      `/v1/admin/banner/${bannerId}/getData`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch banner details:", error);
    throw error;
  }
};

export const updateBanner = async ({
  bannerId,
  text,
  link,
  imageId,
}: {
  bannerId: string;
  text: string;
  link: string;
  imageId: string;
}): Promise<{ message: string }> => {
  try {
    const response = await api.patch<{ message: string }>(
      `/v1/admin/banner/${bannerId}/edit`,
      {
        text,
        link,
        imageId,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update banner:", error);
    throw error;
  }
};

export const deleteBanner = async (
  bannerId: string
): Promise<{ message: string }> => {
  try {
    const response = await api.delete<{ message: string }>(
      `/v1/admin/banner/${bannerId}/delete`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to delete banner:", error);
    throw error;
  }
};

export const createContactForm = async (data: {
  name: string;
  mobile: string;
  email: string;
  description: string;
}) => {
  try {
    const response = await api.post("/v1/contactForm/new", data);
    return response;
  } catch (error) {
    console.error("Failed to create contact form:", error);
    throw error;
  }
};

export const fetchListPosts = async (
  params: FetchPostsParams
): Promise<PostsResponse> => {
  try {
    const response = await api.get<PostsResponse>("/v1/posts/list", {
      params,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch posts:", error);
    throw error;
  }
};

export const fetchBanners = async (): Promise<Banner[]> => {
  try {
    const response = await api.get<Banner[]>("/v1/banners/list");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch banners:", error);
    throw error;
  }
};

export const fetchBlogDetail = async (
  postId: string
): Promise<EditPostData> => {
  try {
    const response = await axios.get<EditPostData>(
      `https://api.sarmadbs.com/v1/post/${postId}/getData`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch post details:", error);
    throw error;
  }
};
