import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import PrivateRoute from "routes/PrivateRoute";

const Dashboard = lazy(() => import("./Dashboard"));
const Posts = lazy(() => import("./Posts"));
const NewPost = lazy(
  () => import("../../../components/AdminComponents/Posts/NewPost")
);
const ContactUs = lazy(() => import("./ContactUs"));
const Categories = lazy(() => import("./Categories"));
const Banners = lazy(() => import("./Banners"));
const NotFound = lazy(() => import("components/AdminComponents/NotFound"));

const AdminRoutes: RouteObject[] = [
  {
    path: "dashboard",
    element: (
      <PrivateRoute
        component={Dashboard}
        title={"routers.dashboard"}
        requiresAuth={true}
      />
    ),
  },
  {
    path: "manage/posts",
    element: (
      <PrivateRoute
        component={Posts}
        title={"routers.posts"}
        requiresAuth={true}
      />
    ),
  },
  {
    path: "new/post",
    element: (
      <PrivateRoute
        component={NewPost}
        title={"routers.newpost"}
        requiresAuth={true}
      />
    ),
  },
  {
    path: "manage/contactUs",
    element: (
      <PrivateRoute
        component={ContactUs}
        title={"routers.managecontactUs"}
        requiresAuth={true}
      />
    ),
  },
  {
    path: "manage/categories",
    element: (
      <PrivateRoute
        component={Categories}
        title={"routers.categories"}
        requiresAuth={true}
      />
    ),
  },
  {
    path: "manage/banners",
    element: (
      <PrivateRoute
        component={Banners}
        title={"routers.banners"}
        requiresAuth={true}
      />
    ),
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default AdminRoutes;
