import contactUs from './pages/contactUs.json';
import routers from './pages/routers.json';
import common from './pages/common.json';
import footer from './pages/footer.json';
import panelNav from './pages/panelNav.json';
import posts from './pages/posts.json';
import login from './pages/login.json';
import categories from './pages/categories.json';
import banners from './pages/banners.json';
import dashboard from './pages/dashboard.json';

export default {
  routers,
  common,
  footer,
  contactUs,
  panelNav,
  posts,
  login,
  categories,
  banners,
  dashboard,
};
