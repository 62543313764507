import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LocalizedFontProvider from "LocalizedFontProvider";
import ThemeWrapper from "ThemeWrapper";
import { PageTitleProvider } from "components/PageTitleContext";
import ThemeProvider from "store/AppConfig/index.context";
import { read, store } from "helper/localStorage";

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const storedLanguage = read("language") || "fa";
    i18n.changeLanguage(storedLanguage);
    document.body.dir = i18n.dir();
  }, [i18n]);

  return (
    <BrowserRouter>
      <ThemeProvider>
        <LocalizedFontProvider>
          <PageTitleProvider>
            <ThemeWrapper />
          </PageTitleProvider>
        </LocalizedFontProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
